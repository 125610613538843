import { graphql, Link } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import CardPost from "../components/CardPost";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import LogoLabel from "../components/LogoLabel";
import MyProfile from "../components/MyProfile";
import { HERO_PARAGRAPH, WEBSITE_DESCRIPTION, WEBSITE_TITLE, WEBSITE_URL } from "../constants";
import { meta } from "../meta";
import * as styles from "./index.css";

export const query = graphql`
  query PageIndex {
    ogImage: file(relativePath: { eq: "og.png" }) {
      childImageSharp {
        original {
          src
          width
          height
        }
      }
    }
    allPost: allMarkdownRemark(
      sort: { fields: frontmatter___createdAt, order: DESC }
    ) {
      nodes {
        id
        ...CardPost_post
      }
    }
  }
`;

interface PageIndexProps {
  data: GatsbyTypes.PageIndexQuery;
}
const PageIndex: React.FC<PageIndexProps> = (props) => {
  const title = WEBSITE_TITLE;
  const description = WEBSITE_DESCRIPTION;

  return (
    <div className={styles.container}>
      <Helmet
        htmlAttributes={{
          lang: "ko",
        }}
        title={title}
        meta={[
          ...meta({
            url: WEBSITE_URL,
            title,
            description,
            thumbnail: {
              url: props.data.ogImage?.childImageSharp?.original?.src,
              width: props.data.ogImage?.childImageSharp?.original?.width,
              height: props.data.ogImage?.childImageSharp?.original?.height,
            },
          }),
        ]}
      />
      <div className={styles.top}>
        <div className={styles.topLeft} />
        <div className={styles.topRight}>
          <Link to="/" className={styles.topLogoLabelLink}>
            <LogoLabel />
          </Link>
        </div>
      </div>
      <div className={styles.hero}>
        <div className={styles.heroTitle}>{HERO_PARAGRAPH}</div>
        <div className={styles.heroProfile}>
          <MyProfile />
        </div>
      </div>
      <div className={styles.cards}>
        {props.data.allPost.nodes.map((post) => (
          <CardPost key={post.id} post={post} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default PageIndex;
